import React, { useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useJwt } from 'react-jwt';
import LoginPage from './pages/Login';
import UnprotectedRoutes from './navigation/UnprotectedRoutes';
import { USER_TYPES } from './helpers/constants';
import ProtectedRoutes from './navigation/ProtectedRoutes';
import LocalStorageServices from './helpers/LocalStorageServices';
import {
  getProfileRequest,
  setAllocatedCountries, setProfile, setSystemCountry, setToken,
} from './store/actions/account';
import { SidebarData } from './helpers/SidebarData';
import { getCountriesRequest, getInfoTableRequest } from './store/actions/staticData';
import PasswordServices from './helpers/PasswordServices';
import { getComplaintsCountRequest, getComplaintsCountSuccess } from './store/actions/reports';

function App() {
  const dispatch = useDispatch();
  const [isReady, setReady] = useState(false);

  const { token, profile, systemCountry } = useSelector((store) => store.account);
  const { decodedToken, isExpired } = useJwt(token);

  const isRoot = decodedToken?.type === USER_TYPES.root;
  const isAdmin = decodedToken?.type === USER_TYPES.admin;
  const isUser = decodedToken?.type === USER_TYPES.user;

  const authorizedFirstScreen = isRoot ? SidebarData.admins.path : isAdmin ? Object.values(SidebarData)[0].path : '';

  const hasAccess = useMemo(() => token && !isExpired && !isUser, [token, !isExpired, !isUser]);

  const filteredRoutes = useMemo(() => {
    if (!profile?._id) return [];

    const list = [];

    if (profile.isAdmin) {
      if (profile.can_edit_countries_and_cities) {
        list.push(SidebarData.cities);
      }
      if (profile.change_info_countries?.length) {
        list.push(SidebarData.articles);
        list.push(SidebarData.gallery);
      }
      if (profile.view_complains_countries?.length) {
        list.push(SidebarData.reports);
      }
      if (profile.can_modify_users_and_orgs) {
        list.push(SidebarData.users);
      }
      if (profile.can_edit_orgs || profile.can_edit_only_own_orgs || profile.can_modify_users_and_orgs) {
        list.push(SidebarData.organizations);
      }
    } else {
      list.push(SidebarData.admins);
    }

    return list;
  }, [profile]);

  useEffect(() => {
    const tokenFromStorage = LocalStorageServices.getToken();
    const profileFromStorage = LocalStorageServices.getProfile();
    const systemCountryFromStorage = LocalStorageServices.getSystemCountry();

    if (tokenFromStorage) {
      if (systemCountryFromStorage?._id) {
        dispatch(setSystemCountry(systemCountryFromStorage));
      }
      dispatch(setToken(tokenFromStorage));
      dispatch(setProfile(profileFromStorage));
      dispatch(getCountriesRequest());
    }

    if (profileFromStorage.isAdmin) {
      dispatch(getProfileRequest());
    }

    setReady(true);
  }, [dispatch, token]);

  useEffect(() => {
    if (!profile?._id) return;
    const countries = profile.view_complains_countries?.length ? profile.view_complains_countries : profile.change_info_countries;
    if (!countries?.length) return;
    dispatch(setAllocatedCountries(countries));
    const index = systemCountry?._id ? countries.findIndex((item) => item._id === systemCountry._id) : 0;
    dispatch(setSystemCountry(countries[index === -1 ? 0 : index]));
  }, [profile]);

  useEffect(() => {
    if (!systemCountry?._id) return;

    dispatch(getInfoTableRequest({
      country_id: systemCountry._id,
      only_visible: 'false',
      block_to_populate: 'all',
    }));
  }, [systemCountry]);

  useEffect(() => {
    if (filteredRoutes?.find((item) => item.path === SidebarData.gallery.path) && systemCountry?._id) {
      dispatch(getComplaintsCountRequest({ systemCountry }));
    }
  }, [filteredRoutes, systemCountry]);

  if (!isReady) {
    return null;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} hasAccess={hasAccess} />}
        >
          <Route index element={<LoginPage />} />
        </Route>

        <Route path="/" element={<ProtectedRoutes hasAccess={hasAccess} />}>
          {filteredRoutes.map((item) => {
            if (item.pathPrefixes?.length) {
              return item.pathPrefixes.map((prefix) => (
                <Route key={item.path + prefix.path} path={item.path + prefix.path} element={prefix.component} />
              ));
            }
            return (
              <Route key={item.path} path={item.path} element={item.component} />
            );
          })}

          <Route path="/" element={<Navigate to={hasAccess ? filteredRoutes[0].path : '/login'} replace />} />
          <Route path="*" element={<Navigate to={hasAccess ? filteredRoutes[0].path : '/login'} replace />} />
        </Route>

        <Route path="*" element={<Navigate to={hasAccess ? authorizedFirstScreen : '/login'} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
