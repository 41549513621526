import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import MyModal from '../../../../components/MyModal';
import { ADMIN_MODAL_STATES } from '../../constants';
import MyButton from '../../../../components/MyButton';
import Api from '../../../../Api';
import sendRequest from '../../../../helpers/sendRequest';
import SearchInput from '../../../../components/SearchInput';
import useInputState from '../../../../helpers/hooks/useInputState';
import { addAdmin, deleteAdmin, updateAdmin } from '../../../../store/actions/admins';
import MyCheckbox from '../../../../components/MyCheckbox';
import { REGEXP } from '../../../../helpers/constants';
import convertStringToSearchable from '../../../../helpers/convertStringToSearchable';
import PasswordServices from '../../../../helpers/PasswordServices';
import includesObject from '../../../../helpers/includesObject';

function AdminModal({
  isVisible, setIsVisible, modalState, setModalState, selectedAdmin = {}, setSelectedAdmin,
}) {
  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.staticData);

  const [name, setName, nameError, setNameError] = useInputState('');
  const [email, setEmail, emailError, setEmailError] = useInputState('');
  const [password, setPassword, passwordError, setPasswordError] = useInputState('');

  const [countrySearch, setCountrySearch] = useInputState('');
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const [canModifyInfos, setCanModifyInfos] = useState(false);
  const [canModifyOrgsAndUsers, setCanModifyOrgsAndUsers] = useState(false);
  const [canEditOrgs, setCanEditOrgs] = useState(false);
  const [canEditOnlyOwnOrgs, setCanEditOnlyOwnOrgs] = useState(false);
  const [canViewReports, setCanViewReports] = useState(false);
  const [canModifyCities, setCanModifyCities] = useState(false);

  const [accessError, setAccessError] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (loading) {
      return;
    }
    setIsVisible(false);
    setTimeout(() => {
      setSelectedAdmin({});
      setLoading(false);
      cleanFirstData();
      cleanSelectCountriesData();
    }, 400);
  };

  const cleanFirstData = () => {
    setName('', true);
    setEmail('', true);
    setPassword('', true);
    setAccessError('');
    setCanModifyInfos(false);
    setCanModifyOrgsAndUsers(false);
    setCanEditOrgs(false);
    setCanEditOnlyOwnOrgs(false);
    setCanViewReports(false);
    setCanModifyCities(false);
  };

  const cleanSelectCountriesData = () => {
    setSelectedCountries([]);
    setCountrySearch('', true);
  };

  const checkFirstState = () => {
    let flag = false;
    if (name?.length < 2) {
      setNameError('Минимум 2 символа');
      flag = true;
    }
    if (!REGEXP.email.test(email)) {
      setEmailError('Неправильный формат email');
      flag = true;
    }
    if (password?.length < 6) {
      setPasswordError('Минимум 6 символов');
      flag = true;
    }
    if (!canModifyInfos && !canModifyOrgsAndUsers && !canViewReports && !canModifyCities && !canEditOrgs && !canEditOnlyOwnOrgs) {
      setAccessError('Выберите хотя бы один из этих прав');
      flag = true;
    }

    if (flag) return;

    if (canModifyInfos || canViewReports) {
      setModalState(ADMIN_MODAL_STATES.selectCountries);
    } else {
      setModalState(ADMIN_MODAL_STATES.confirm);
    }
  };

  const onNextClick = () => {
    if (modalState === ADMIN_MODAL_STATES.first) {
      checkFirstState();
    } else if (modalState === ADMIN_MODAL_STATES.selectCountries) {
      setModalState(ADMIN_MODAL_STATES.confirm);
    } else if (modalState === ADMIN_MODAL_STATES.confirm) {
      if (selectedAdmin?._id) {
        onEditAdmin().then();
      } else {
        onAddAdmin().then();
      }
    }
  };

  const onBackClick = () => {
    if (modalState === ADMIN_MODAL_STATES.first) {
      closeModal();
    } else if (modalState === ADMIN_MODAL_STATES.selectCountries) {
      cleanSelectCountriesData();
      setModalState(ADMIN_MODAL_STATES.first);
    } else if (modalState === ADMIN_MODAL_STATES.confirm) {
      if (canModifyInfos || canViewReports) {
        setModalState(ADMIN_MODAL_STATES.selectCountries);
      } else {
        setModalState(ADMIN_MODAL_STATES.first);
      }
    }
  };

  const onCountryClick = (country) => {
    if (includesObject(selectedCountries, country)) {
      setSelectedCountries((prev) => prev.filter((item) => item._id !== country._id));
    } else {
      // if (selectedCountries.length === 2) {
      //   setModalState(ADMIN_MODAL_STATES.confirm);
      // }
      setSelectedCountries((prev) => [...prev, country]);
    }
  };

  useEffect(() => {
    if (!countries?.length) return;

    const timer = setTimeout(() => {
      if (!countrySearch) {
        setFilteredCountries(countries);
      } else {
        setFilteredCountries(countries.filter(
          (country) => convertStringToSearchable(country.name).includes(convertStringToSearchable(countrySearch)),
        ));
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [countrySearch, countries]);

  useEffect(() => {
    if (!selectedAdmin?._id) return;

    setName(selectedAdmin.display_name);
    setEmail(selectedAdmin.email);
    setPassword(PasswordServices.decryptPassword(selectedAdmin.password));
    setCanViewReports(selectedAdmin.view_complains_countries.length);
    setCanModifyInfos(selectedAdmin.change_info_countries.length);
    setCanModifyCities(selectedAdmin.can_edit_countries_and_cities);
    setCanModifyOrgsAndUsers(selectedAdmin.can_modify_users_and_orgs);
    setCanEditOrgs(selectedAdmin.can_edit_orgs);
    setCanEditOnlyOwnOrgs(selectedAdmin.can_edit_only_own_orgs);
    setSelectedCountries(selectedAdmin.view_complains_countries.length ? selectedAdmin.view_complains_countries : selectedAdmin.change_info_countries);
  }, [selectedAdmin]);

  const onAddAdmin = async () => {
    const payload = {
      email,
      password: PasswordServices.encryptPassword(password),
      display_name: name,
      can_edit_countries_and_cities: canModifyCities,
      can_modify_users_and_orgs: canModifyOrgsAndUsers,
      can_edit_orgs: canEditOrgs,
      can_edit_only_own_orgs: canEditOnlyOwnOrgs,
      view_complains_country_ids: canViewReports ? selectedCountries.map((item) => item._id) : [],
      change_info_country_ids: canModifyInfos ? selectedCountries.map((item) => item._id) : [],
    };

    const data = await sendRequest({
      request: Api.addAdmin,
      payload,
      warnErrorText: 'while creating admin',
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(addAdmin(data.admin));
      closeModal();
    } else {
      setIsVisible(false);
    }
  };

  const onDeleteClick = async () => {
    const data = await sendRequest({
      request: Api.deleteAdmin,
      payload: selectedAdmin._id,
      warnErrorText: 'while deleting admin',
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(deleteAdmin(selectedAdmin));
      closeModal();
    } else {
      setIsVisible(false);
    }
  };

  const onEditAdmin = async () => {
    const payload = {
      email,
      password: PasswordServices.encryptPassword(password),
      display_name: name,
      can_edit_countries_and_cities: canModifyCities,
      can_modify_users_and_orgs: canModifyOrgsAndUsers,
      can_edit_orgs: canEditOrgs,
      can_edit_only_own_orgs: canEditOnlyOwnOrgs,
      view_complains_country_ids: canViewReports ? selectedCountries.map((item) => item._id) : [],
      change_info_country_ids: canModifyInfos ? selectedCountries.map((item) => item._id) : [],
    };

    const data = await sendRequest({
      request: Api.editAdmin,
      payload: { id: selectedAdmin._id, data: payload },
      warnErrorText: 'while editing admin',
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(updateAdmin(data.admin));
      closeModal();
    }
  };

  return (
    <MyModal isOpen={isVisible} closeFunction={closeModal}>
      <div className="admin_modal">
        {modalState === ADMIN_MODAL_STATES.delete ? (
          <>
            <p className="modal_title">Удалить?</p>
            <p className="modal_info_name">{selectedAdmin.display_name}</p>
            <p className="modal_info">
              Вы уверены что хотите удалить этого администратора? Отменить данное
              действие будет невозможно.
            </p>

            <div className="modal_buttons_row buttons_with_margin">
              <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
              <MyButton
                className="modal_button"
                text="Удалить"
                loading={loading}
                onClick={onDeleteClick}
              />
            </div>
          </>
        ) : (
          <>
            <div className="modal_top_part">
              <p className="modal_title">
                {selectedAdmin?._id ? 'Изменить' : 'Добавить'}
                {' '}
                администратора
              </p>

              {modalState === ADMIN_MODAL_STATES.first ? (
                <>
                  <div className="modal_simple_block">
                    <p className="modal_simple_block_title">Личные данные</p>
                  </div>

                  <SearchInput
                    wrapperClassName="modal_input"
                    value={name}
                    onChange={setName}
                    error={nameError}
                    placeholder="Имя"
                    withLeftIcon={false}
                  />
                  <SearchInput
                    wrapperClassName="modal_input"
                    value={email}
                    onChange={setEmail}
                    error={emailError}
                    placeholder="Email"
                    withLeftIcon={false}
                  />
                  <SearchInput
                    wrapperClassName="modal_input"
                    value={password}
                    onChange={setPassword}
                    error={passwordError}
                    placeholder="Пароль"
                    withLeftIcon={false}
                  />
                  <div className="modal_simple_block margin">
                    <p className="modal_simple_block_title">Права</p>
                    {accessError && <p className="modal_simple_block_error">{accessError}</p>}
                  </div>
                  <div className="modal_checkboxes_block">
                    <MyCheckbox value={canModifyInfos} setValue={setCanModifyInfos} text="Редактировать информацию (3 страны)" />
                    <MyCheckbox value={canModifyOrgsAndUsers} setValue={setCanModifyOrgsAndUsers} text="Бан/разбан организаций и юзеров" />
                    <MyCheckbox value={canEditOrgs} setValue={setCanEditOrgs} text="Создавать и редактировать организации" />
                    <MyCheckbox value={canEditOnlyOwnOrgs} setValue={setCanEditOnlyOwnOrgs} text="Создавать и редактировать только свои организации" />
                    <MyCheckbox value={canViewReports} setValue={setCanViewReports} text="Рассматривать жалобы (3 страны)" />
                    <MyCheckbox value={canModifyCities} setValue={setCanModifyCities} text="Редактировать страны и города" />
                  </div>
                </>
              ) : modalState === ADMIN_MODAL_STATES.selectCountries ? (
                <>
                  <div className="modal_simple_block">
                    <p className="modal_simple_block_title">Страны (не более 3)</p>
                  </div>

                  <SearchInput
                    wrapperClassName="modal_input"
                    value={countrySearch}
                    onChange={setCountrySearch}
                    placeholder="Поиск"
                    withLeftIcon={false}
                  />

                  <div className="modal_countries_scroll">
                    {filteredCountries.map((country) => (
                      <MyCheckbox
                        key={country._id}
                        wrapperClassName="modal_countries_checkbox"
                        imgUrl={Api.url + country.flag_url}
                        value={includesObject(selectedCountries, country)}
                        setValue={() => onCountryClick(country)}
                        disabled={selectedCountries.length >= 3 && !includesObject(selectedCountries, country)}
                        text={country.name}
                      />
                    ))}
                  </div>
                </>
              ) : modalState === ADMIN_MODAL_STATES.confirm ? (
                <>
                  <div className="modal_simple_block">
                    <p className="modal_simple_block_title">Личные данные</p>
                  </div>

                  <div className="modal_simple_block">
                    <p className="modal_simple_block_text">{name}</p>
                    <p className="modal_simple_block_text">{email}</p>
                    <p className="modal_simple_block_text">{password}</p>
                  </div>

                  <div className="modal_simple_block margin">
                    <p className="modal_simple_block_title">Права</p>
                  </div>
                  <div className="modal_simple_block">
                    {!!canModifyInfos && <p className="modal_simple_block_text">Редактировать информацию</p>}
                    {!!canModifyOrgsAndUsers && <p className="modal_simple_block_text">Бан/разбан организаций и юзеров</p>}
                    {!!canEditOrgs && <p className="modal_simple_block_text">Редактировать организации</p>}
                    {!!canEditOnlyOwnOrgs && <p className="modal_simple_block_text">Редактировать только свои организации</p>}
                    {!!canViewReports && <p className="modal_simple_block_text">Рассматривать жалобы</p>}
                    {!!canModifyCities && <p className="modal_simple_block_text">Редактировать страны и города</p>}
                  </div>

                  <div className="modal_simple_block margin">
                    <p className="modal_simple_block_title">Страны</p>
                  </div>

                  <div className="modal_simple_block">
                    <div className="modal_simple_confirm_countries">
                      {selectedCountries.length ? selectedCountries.map(
                        (country) => <img key={country._id} className="modal_simple_confirm_countries_item" src={Api.url + country.flag_url} alt="" />,
                      ) : <p className="modal_simple_block_text">────</p>}
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="modal_buttons_row">
              <MyButton
                className="modal_button"
                text={modalState === ADMIN_MODAL_STATES.first ? 'Отмена' : 'Назад'}
                secondary
                onClick={onBackClick}
              />
              <MyButton
                className="modal_button"
                text={modalState === ADMIN_MODAL_STATES.confirm ? 'Сохранить' : 'Далее'}
                loading={loading}
                onClick={onNextClick}
              />
            </div>
          </>
        )}
      </div>
    </MyModal>
  );
}

export default AdminModal;
